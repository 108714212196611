import { IoCheckmarkCircle } from 'react-icons/io5'
import './ConfirmBookingPage.css'

export default function ConfirmBookingPage(props: {
    isRetailStore: boolean,
}) {
    return (
        <div id='ConfirmBookingPage' className='canvasDark'>
            <IoCheckmarkCircle size={278} className='CanvasDark' />
            <h2>{`Your ${props.isRetailStore ? 'Order placed' : 'Appointment Booked'} Successfully!`} </h2>
            <p> {`We have send your  ${props.isRetailStore ? 'order' : 'booking'} information to your email address.`}  </p>

            <button
                style={{ width: '172px', height: '42px', }}
                className='canvas'
                aria-label='Go Home'
                onClick={window.location.reload
                } >Go Home</button>
        </div>
    )
}
