import './TermsAndConditions.css'
import { showTermsAndConditions } from '../App/App_state'
import { useSetAtom } from 'jotai'

export default function TermsAndConditionsDialog(props: {
  termsAndConditions: String,
}) {
  const setShowTermsAndConditions = useSetAtom(showTermsAndConditions)

  return (
    <div id='TermsAndConditionsDialog' className='canvas' >

      <h2>Terms & Conditions</h2>

      <div style={{
        overflow: 'scroll',
      }}>
        {props.termsAndConditions.replaceAll('• ', '\n• ')}
      </div>



      <button
        aria-label='Cancel'
        className='canvasButton canvasDark'
        onClick={() => setShowTermsAndConditions(false)}
      >
        Accept
      </button>

    </div>
  )
}
