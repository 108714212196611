import { StoreDetail } from '../App/App_Store_interface';
import { IconWrapper, formatPhoneNumber } from '../GetInTouch/GetInTouch';

import { IoLocationOutline, IoFlashOutline, IoPersonOutline, IoCallOutline, IoMailOutline, IoInformationCircleOutline } from 'react-icons/io5';

export default function SalonCard(props: {
  storeDetail: StoreDetail,
  isRetailStore: boolean,
  isLargeScreen: boolean,
}): JSX.Element {

  if (props.isLargeScreen) {
    let style = {
      display: 'grid',
      gap: '7px',
      gridColumn: '',
      gridRow: '',
    };

    if (props.isLargeScreen && (props.isRetailStore ? props.storeDetail!.retailBookingMedia : props.storeDetail!.bookingMedia).length === 0) {
      style = {
        ...style,
        gridColumn: '1 / span 3',
        gridRow: '1 / span 1',
      };
    }
    return (
      <div
        id='salonCard_container'
        className='canvas paddingMin'
        style={style}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
        }}>
          <img src={props.storeDetail.logo} className='radiusMax' alt='logo' width='147' height='147' style={{
            objectFit: 'fill',
          }} />

          <h2 style={{
            flex: '1',
            paddingLeft: '14px',
          }}>
            {props.storeDetail.name} <br />
            {props.storeDetail.chainName} <br />
          </h2>

          {props.storeDetail.totalStore > 1 &&
            <a
              href={`https://${window.location.host}/${window.location.pathname.split('/').at(1)}`}
              style={{
                padding: '4px',
                color: 'blue',
                height: '42px'
              }}
            >
              change location
            </a>
          }


          <div style={{
            display: 'grid',
            gap: '1px',
            gridAutoRows: '52px 52px 52px 52px'
          }}>
            {[{
              icon: <IoLocationOutline size={24} />,
              title: 'Location',
              value: `${props.storeDetail.address.line1},  ${props.storeDetail.address.line2}`,
              href: `https://maps.google.com/?q=${(props.storeDetail.address.placeId !== undefined && props.storeDetail.address.placeId !== null) ? props.storeDetail.address.placeId : `${props.storeDetail.address.lat},${props.storeDetail.address.long}`}`,
              isInActive: props.storeDetail.address.lat === undefined || props.storeDetail.address.lat === null,
            }, {
              icon: <IoCallOutline size={24} />,
              title: 'Phone Number',
              value: formatPhoneNumber(props.storeDetail.contactInfo.phoneNumber),
              href: `tel://${props.storeDetail.contactInfo.phoneNumber}`,
              isInActive: props.storeDetail.contactInfo.phoneNumber === undefined || props.storeDetail.contactInfo.phoneNumber === null,
            }, {
              icon: <IoMailOutline size={24} />,
              title: 'Email',
              value: props.storeDetail.contactInfo.email,
              href: `mailto:${props.storeDetail.contactInfo.email}`,
              isInActive: props.storeDetail.contactInfo.email === undefined || props.storeDetail.contactInfo.email === null,
            }, {
              icon: <IoInformationCircleOutline size={24} />,
              title: 'About Us',
              value: props.storeDetail.contactInfo.email,
              href: `https://${window.location.host}/${window.location.pathname.split('/').at(1)}/aboutUs`,
              isInActive: props.storeDetail.aboutUsText === undefined || props.storeDetail.aboutUsText === null,
            }
            ]
              .filter(e => e.isInActive === false)
              .map((product, i) =>
                <a
                  key={i}
                  href={product.href}
                  aria-label={product.value}
                >
                  <IconWrapper child={product.icon} title={product.title} />
                </a>
              )}
          </div>
        </div>

        <div style={{
          borderBottom: '0.2px solid lightgray',
        }}></div>

        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
        }}>
          {props.isRetailStore === false && <SalonBookingType title='Instant Booking' subtitle='No Queue at store' icon={<IoFlashOutline size={'22'} />} />}
          <SalonBookingType title='Guest Booking' subtitle='No account required' icon={<IoPersonOutline size={'22'} />} />
        </div>
      </div>
    )
  }

  return (
    <div
      id='salonCard_container'
      className='canvas paddingMin'
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '7px',
        gridColumn: '',
        gridRow: '',
      }}
    >
      <img src={props.storeDetail.logo} className='radiusMax' alt='logo' style={{
        objectFit: 'contain',
        width: '100%',
        maxHeight: '272px',
      }} />

      <h2 style={{
        flex: '1',
        paddingLeft: '14px',
      }}>
        {props.storeDetail.name} <br />
        {props.storeDetail.chainName} <br />
      </h2>

      <div style={{
        display: 'flex',
        gap: '9px',
        // gridColumn: '52px 52px 52px 52px'
      }}>
        {[{
          icon: <IoLocationOutline size={24} />,
          title: 'Location',
          value: `${props.storeDetail.address.line1},  ${props.storeDetail.address.line2}`,
          href: `https://maps.google.com/?q=${(props.storeDetail.address.placeId !== undefined && props.storeDetail.address.placeId !== null) ? props.storeDetail.address.placeId : `${props.storeDetail.address.lat},${props.storeDetail.address.long}`}`,
          isInActive: props.storeDetail.address.lat === undefined || props.storeDetail.address.lat === null,
        }, {
          icon: <IoCallOutline size={24} />,
          title: 'Phone Number',
          value: formatPhoneNumber(props.storeDetail.contactInfo.phoneNumber),
          href: `tel://${props.storeDetail.contactInfo.phoneNumber}`,
          isInActive: props.storeDetail.contactInfo.phoneNumber === undefined || props.storeDetail.contactInfo.phoneNumber === null,
        }, {
          icon: <IoMailOutline size={24} />,
          title: 'Email',
          value: props.storeDetail.contactInfo.email,
          href: `mailto:${props.storeDetail.contactInfo.email}`,
          isInActive: props.storeDetail.contactInfo.email === undefined || props.storeDetail.contactInfo.email === null,
        }, {
          icon: <IoInformationCircleOutline size={24} />,
          title: 'About Us',
          value: props.storeDetail.contactInfo.email,
          href: `https://${window.location.host}/${window.location.pathname.split('/').at(1)}/aboutUs`,
          isInActive: props.storeDetail.aboutUsText === undefined || props.storeDetail.aboutUsText === null,
        }
        ]
          .filter(e => e.isInActive === false)
          .map((product, i) =>
            <a
              style={{ minWidth: '99px' }}
              key={i}
              href={product.href}
              aria-label={product.value}
            >
              <IconWrapper child={product.icon} title={product.title} />
            </a>
          )}
      </div>

      <div style={{
        borderBottom: '0.2px solid lightgray',
      }}></div>

      <div style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
      }}>
        {props.isRetailStore === false && <SalonBookingType title='Instant Booking' subtitle='No Queue at store' icon={<IoFlashOutline size={'22'} />} />}
        <SalonBookingType title='Guest Booking' subtitle='No account required' icon={<IoPersonOutline size={'22'} />} />
      </div>

    </div >
  )
}

function SalonBookingType(props: {
  title: string;
  subtitle?: string;
  icon: JSX.Element;
}): JSX.Element {
  return (
    <div>
      <p>
        {props.icon}
        {props.title}
      </p>
      <p>
        <small>{props.subtitle}</small>
      </p>
    </div>
  )
}
