import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/App/App';
import { Provider } from 'jotai';

ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
).render(
  <Provider>
    <App />
  </Provider>
)