import { useAtomValue, useSetAtom } from 'jotai';
import { useState } from 'react';
import { IoCloseOutline } from 'react-icons/io5'
import { selectedEmployeeIdAtom, selectedTimeAtom } from '../Booking/Booking_state';
import TextField from '../TextField/TextField';
import './SlideDrawer.css'

import { add } from 'date-fns';
import { cartAtom, showBookingDrawer, showBookingSuccessMessageAtom, showTermsAndConditions, storeDetailAtom } from '../App/App_state';
import { getAuth } from '@firebase/auth';

export default function SlideDrawer(props: {
  show: boolean
}) {
  // state to toggle between select services and Booking Page 
  const toggleShowBookingDrawer = useSetAtom(showBookingDrawer);

  const setShowBookingSuccessMessage = useSetAtom(showBookingSuccessMessageAtom)

  const setShowTermsAndConditions = useSetAtom(showTermsAndConditions)


  const storeDetail = useAtomValue(storeDetailAtom)

  const [chainId, storeId, storeType] = window.location.pathname.split('/').splice(1)

  const isRetailStore = storeType === 'retail-booking'


  // array of selected items
  const cart = useAtomValue(cartAtom)


  const selectedEmployeeId = useAtomValue(selectedEmployeeIdAtom)
  const selectedTime = useAtomValue(selectedTimeAtom)


  // form textfield state
  const [data, setData] = useState({
    customerFirstName: '',
    customerLastName: '',
    customerIsMale: true,
    customerEmail: '',
    customerPhoneNumber: '',
    remark: '',
    Address: '',
    pincode: '',
    agreedTermsAndConditions: false,
  })

  const [showSpinner, setShowSpinner] = useState(false)


  let validate = () => {
    if (isRetailStore && (data.Address.length > 2 && data.pincode.length > 5 && !isNaN(parseFloat(data.pincode))) === false) {
      return false
    }

    if ((storeDetail?.termsAndConditions !== undefined && storeDetail?.termsAndConditions !== null) && data.agreedTermsAndConditions === false) {
      return false;
    }

    if (data.customerFirstName.includes(' ') || data.customerLastName.includes(' ')) {
      return false;
    }

    return data.customerFirstName.length > 2
      && data.customerLastName.length > 0
      && data.customerEmail.length > 2
      && data.customerPhoneNumber.length > 9
      && data.customerPhoneNumber.length < 12
      && !isNaN(parseFloat(data.customerPhoneNumber))

  }

  return (
    <div className={props.show ? 'side-drawer open canvas' : 'side-drawer'}>

      <button aria-label='Close Drawer' onClick={() => toggleShowBookingDrawer((v) => !v)}>
        <IoCloseOutline size={42} color={'grey'} />
      </button>

      <h3>Enter Your Details</h3>
      <small>Enter Your Details to continue.</small>


      <TextField label={'First Name'} value={data.customerFirstName} onChange={(e) => setData({ ...data, customerFirstName: e.target.value })} />
      <TextField label={'Last Name'} value={data.customerLastName} onChange={(e) => setData({ ...data, customerLastName: e.target.value })} />

      <div id="genderRadio">
        <p>Gender</p>

        <input
          type='checkbox'
          id='maleRadioButton'
          aria-labelledby='maleRadioButton'
          checked={data.customerIsMale}
          onChange={() => setData({
            ...data,
            customerIsMale: true,
          })} /> Male
        <label htmlFor='femaleRadioButton'></label>

        <input
          id='femaleRadioButton'
          type='checkbox'
          checked={data.customerIsMale === false}
          onChange={() => setData({
            ...data,
            customerIsMale: false,
          })} /> Female
        <label htmlFor='femaleRadioButton'></label>
      </div>



      <TextField label={'Email Address'} value={data.customerEmail} onChange={(e) => setData({ ...data, customerEmail: e.target.value })} />
      <TextField label={'Mobile Number'} value={data.customerPhoneNumber} onChange={(e) => setData({ ...data, customerPhoneNumber: e.target.value })} />
      {isRetailStore && <TextField label={'Address'} value={data.Address} onChange={(e) => setData({ ...data, Address: e.target.value })} />}
      {isRetailStore && <TextField label={'Pincode'} value={data.pincode} onChange={(e) => setData({ ...data, pincode: e.target.value })} />}
      <TextField label={'Remark'} value={data.remark ?? ''} onChange={(e) => setData({ ...data, remark: e.target.value })} />

      {isRetailStore && <p style={{ padding: '14px 0' }}> Cash on Delivery</p>}


      {
        storeDetail?.termsAndConditions !== undefined && storeDetail?.termsAndConditions !== null
          ?

          <small style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            alignItems: 'center',
            marginBottom: '24px'
          }}>
            <input
              type='checkbox'
              id='termsAndConditionCheckBox'
              aria-labelledby='term & conditions'
              checked={data.agreedTermsAndConditions}
              onChange={() => setData({
                ...data,
                agreedTermsAndConditions: data.agreedTermsAndConditions === false,
              })}
            />
            <div style={{ width: '7px' }}></div>

            I agree to the

            <label htmlFor='termsAndConditionCheckBox'></label>

            <div style={{ width: '4px' }}></div>

            <button
              style={{ color: 'blue', width: 'unset' }}
              onClick={() => {
                if (data.agreedTermsAndConditions === false) {
                  setData({
                    ...data,
                    agreedTermsAndConditions: true,
                  })
                }
                setShowTermsAndConditions(true);
              }}
            >
              terms & Conditions
            </button>
          </small>
          : <></>
      }


      {
        showSpinner ?
          <div className='loaderSmall'></div>
          : <button aria-label='Add Salon Booking' onClick={async () => {

            if (showSpinner || validate() === false) {
              return
            }

            const bookingDocData = {
              customerFirstName: data.customerFirstName,
              customerLastName: data.customerLastName,
              customerEmail: data.customerEmail,
              customerPhoneNumber: data.customerPhoneNumber,
              agreedTermsAndConditions: data.agreedTermsAndConditions,
              customerIsMale: data.customerIsMale,

              remark: data.remark.length === 0 ? null : data.remark,

              storeId: storeDetail?.invoayId,

              ...(isRetailStore ? {
                source: 'Grocery App',

                datetime: (selectedTime ?? new Date()).toJSON(),

                address: data.Address,
                pincode: data.pincode,
                items: cart.map((item) => {
                  return {
                    invoayId: item.invoayId,
                    cost: item.cost,
                    qty: item.cartQty,
                  }
                }),

              } : {
                source: 'Booking App',
                employeeId: storeDetail!.employees!.find((employee) => employee.id === selectedEmployeeId)!.invoayId,

                datetimeFrom: selectedTime?.toJSON(),
                datetimeTo: add(selectedTime!, { minutes: cart.map((item) => (item.durationInMinutes ?? 0) * item.cartQty).reduce((a, b) => a + b) }).toJSON(),

                items: cart.map((item) => {
                  return {
                    invoayId: item.invoayId,
                    cost: item.cost,
                    qty: item.cartQty,
                  }
                }),

              })
            }

            setShowSpinner(true)

            const authorization = await getAuth().currentUser?.getIdToken()

            if (authorization === undefined) {
              alert('unauthenticated')
              return;
            }

            fetch("https://booking.invoay.com/addBooking", {
              method: 'POST',
              headers: new Headers([
                ["Content-Type", "application/json"],
                ["token", authorization!],
              ]),
              body: JSON.stringify({
                ...bookingDocData,
                firestoreReferenceId: isRetailStore
                  ? `chains/${chainId}/stores/${storeId}/bookings`
                  : `chains/${chainId}/stores/${storeId}/employees/${selectedEmployeeId}/bookings`,
              }),
            })
              .then(response => {
                if (response.status === 200 || response.status === 204) {
                  setShowBookingSuccessMessage(true)
                }

                return response.text();
              })
              .catch(error => {
                setShowSpinner(false)
                alert('Something Went Wrong');
              });

          }}>
            <div className={`canvasButton ${validate() ? 'canvasDark' : ''}`} style={validate() ? {} : { backgroundColor: 'grey' }}>{isRetailStore ? 'Checkout Now' : 'Book Now'} </div>
          </button>
      }
      <div style={{ height: '442px' }}></div>
    </div >
  )
}