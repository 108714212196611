import { atom } from "jotai"
import { Bookings } from "../App/App_Store_interface"

// state atom holds selected employee id,
const selectedEmployeeIdAtom = atom<string | undefined>(undefined)

// state atom store selected date
const selectedDateAtom = atom<Date>(new Date())

// state atom store selected dateTime
const selectedTimeAtom = atom<Date | null>(null)

// state atom hold list of booking objects for selected date
const bookingsTimeAtom = atom<Array<Bookings>>([])

export { selectedEmployeeIdAtom, selectedDateAtom, selectedTimeAtom, bookingsTimeAtom }