import { useAtomValue } from "jotai";
import { StoreDetail } from "../App/App_Store_interface";
import { showSelectServices } from "../App/App_state";
import "./GetInTouch.css";
import { IoLocationOutline, IoCallOutline, IoLogoFacebook, IoLogoInstagram, IoMailOutline, IoTimeOutline } from 'react-icons/io5';

export default function GetInTouch(props: {
  storeDetail: StoreDetail,
  isLargeScreen: boolean,
}): JSX.Element {

  const timeFrom = new Date(props.storeDetail.timeFrom).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  const timeTo = new Date(props.storeDetail.timeTo).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

  const showSelectServicesState = useAtomValue(showSelectServices)


  return (
    <div
      id="DetailFooter"
      // className='canvasDark'
      style={{
        padding: '7px',
        gridRow: showSelectServicesState
          ? props.isLargeScreen
            ? '4 / span 1'
            : '6 / span 1'
          : props.isLargeScreen
            ? '4 / span 1'
            : '6 / span 1'
      }}
    >

      <div
        style={{ display: 'flex', alignItems: 'start', alignContent: 'start' }}
      >

        <h4>Contact</h4>


        {[{
          icon: <IoLogoFacebook size={24} />,
          href: props.storeDetail.contactInfo.fb,
          isActive: props.storeDetail.contactInfo.fb !== undefined && props.storeDetail.contactInfo.fb !== null,
        }, {
          icon: <IoLogoInstagram size={24} />,
          href: props.storeDetail.contactInfo.insta,
          isActive: props.storeDetail.contactInfo.insta !== undefined && props.storeDetail.contactInfo.insta !== null,
        }]
          .filter(e => e.isActive)
          .map((product, i) =>
            <div key={i} style={{ marginLeft: '7px' }}>
              <a href={product.href} aria-label={product.href} target='_blank' >
                <IconWrapper child={product.icon} title={""} />
              </a>
            </div>
          )}
      </div>

      <h2>{props.storeDetail.name}</h2>
      <h2>{props.storeDetail.chainName}</h2>
      <div style={{ height: '7px' }}></div>


      {[{
        icon: <IoLocationOutline size={24} />,
        value: `${props.storeDetail.address.line1},  ${props.storeDetail.address.line2}`,
        href: `https://maps.google.com/?q=${(props.storeDetail.address.placeId !== undefined && props.storeDetail.address.placeId !== null) ? props.storeDetail.address.placeId : `${props.storeDetail.address.lat},${props.storeDetail.address.long}`}`,
        isInActive: props.storeDetail.address.lat === undefined || props.storeDetail.address.lat === null
      },
      {
        icon: <IoCallOutline size={24} />,
        value: formatPhoneNumber(props.storeDetail.contactInfo.phoneNumber),
        href: `tel://${props.storeDetail.contactInfo.phoneNumber}`,
        isInActive: props.storeDetail.contactInfo.phoneNumber === undefined || props.storeDetail.contactInfo.phoneNumber === null
      },
      {
        icon: <IoMailOutline size={24} />,
        value: props.storeDetail.contactInfo.email,
        href: `mailto:${props.storeDetail.contactInfo.email}`,
        isInActive: props.storeDetail.contactInfo.email === undefined || props.storeDetail.contactInfo.email === null
      }]
        .filter(e => e.isInActive === false)
        .map((product, i) =>
          <a
            key={i}
            href={product.href}
            target="_blank"
            aria-label={product.href}
          >
            <div style={{
              height: '42px',
              display: 'flex',
              alignItems: 'center',
            }}>
              <IconWrapper child={product.icon} title={""} />
              <div style={{ width: '7px' }}></div>
              <p>{product.value}</p>
            </div>
          </a>
        )}

      <div
        style={{
          height: '42px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <IconWrapper child={<IoTimeOutline size={24} />} title={""} />
        <div style={{ width: '7px' }}></div>
        <p>{`${timeFrom} to ${timeTo}`}</p>
      </div>


      <p className='IconWrapper'>{`Copyright ©️ ${props.storeDetail.name} Powered By Invoay 2024`}</p>

    </div >
  );
}

export function IconWrapper(props: {
  child: JSX.Element,
  title: string
}): JSX.Element {
  return <div className='IconWrapper'>
    {props.child}
    <p style={{
      fontSize: '10px'
    }}>
      {props.title}
    </p>
  </div>
}

export function formatPhoneNumber(phoneNumber: string | undefined | null) {
  if (phoneNumber === undefined || phoneNumber === null) {
    return '';
  }
  try {
    return phoneNumber.slice(0, 3) + "-" + phoneNumber!.slice(3, 6) + "-" + phoneNumber!.slice(6)
  } catch (error) {
    return '';
  }
}