import { useAtom, useSetAtom } from 'jotai'
import './RemoveItemConform.css'
import { Item } from '../App/App_Store_interface'
import { cartAtom, removeItemFromCart, showSelectServices } from '../App/App_state'


export default function RemoveItemConform(props: {
  item: Item,
}) {
  // set item that have to remove from cart 
  // then show a dialog to remove that item from cart
  const setRemoveItemDialogItem = useSetAtom(removeItemFromCart)

  const toggleShowSelectServices = useSetAtom(showSelectServices)

  const [cartItems, SetCartItems] = useAtom(cartAtom)

  return (
    <div id='RemoveItemConform' className='canvas' >
      <h3>Confirm</h3>
      {`Remove ${props.item.name}`}
      <div>
        <button
          style={{ backgroundColor: 'lightgrey', color: 'black' }}
          aria-label='Cancel'
          className='canvasButton'
          onClick={() => setRemoveItemDialogItem(null)
          }>Cancel</button>
        <div style={{ width: '4px' }}></div>
        <button
          aria-label='Confirm'
          className='canvasDark'

          style={{ textAlign: 'center' }}
          onClick={() => {
            const newCartItems = cartItems.filter((d) => d.id !== props.item.id)

            if (newCartItems.length === 0) {
              toggleShowSelectServices(true)
            }

            SetCartItems(newCartItems)
            setRemoveItemDialogItem(null)
          }}>Remove</button>
      </div>
    </div>
  )
}
