import './AboutChainComponent.css'
import { StoreDetail } from '../App/App_Store_interface'
import SalonCard from '../salonCard/SalonCard'
import { getAuth, signInAnonymously } from '@firebase/auth'
import { collection, doc, getDoc, getDocs, getFirestore } from 'firebase/firestore'
import { initializeApp } from 'firebase/app'
import { useEffect, useState } from 'react'
import GetInTouch from '../GetInTouch/GetInTouch'

export default function AboutChainComponent() {


  const [stores, setStores] = useState<Array<StoreDetail>>([])

  const [chainId] = window.location.pathname.split('/').splice(1)

  useEffect(() => {
    initializeApp({
      apiKey: "AIzaSyDKhIM0h7ipv8oI32-VFB91iELRvzB9zK4",
      authDomain: "invoay-booking.firebaseapp.com",
      projectId: "invoay-booking",
      storageBucket: "invoay-booking.appspot.com",
      messagingSenderId: "926888529000",
      appId: "1:926888529000:web:320686d52fee33775dffdd",
      measurementId: "G-V8RPDGL417"
    })

    signInAnonymously(getAuth())
      .then(() =>
        Promise.all([
          getDoc(
            doc(
              collection(
                getFirestore(),
                'chains',
              ),
              chainId,
            ),
          ),
          getDocs(
            collection(
              getFirestore(),
              `chains/${chainId}/stores`,
            ),
          )
        ])
      ).then((data) => {
        setStores(data[1].docs.map((d) => {
          return {
            ...d.data(),
            aboutUsText: data[0].get('aboutUsText'),
            id: d.id,
          }
        }) as Array<StoreDetail>)
      })

  }, [chainId])

  if (stores.length === 0) {
    return <div className='loader'></div>
  }


  return (
    <div className='canvas' style={{
      padding: '14px',
      height: '100vh',
    }}>
      <SalonCard storeDetail={{ ...stores[0], aboutUsText: undefined }} isRetailStore={false} isLargeScreen={false} />
      <h2>About Us</h2>

      <div style={{
        overflow: 'scroll',
        paddingTop: '14px',
        height: '100%'
      }}>
        {stores[0].aboutUsText}
      </div>


    </div>
  )
}