import './Cart.css'
import { IoGridOutline, IoRemoveCircleSharp, IoAddCircle } from 'react-icons/io5'
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { selectedDateAtom, selectedEmployeeIdAtom, selectedTimeAtom } from '../Booking/Booking_state';
import { format } from 'date-fns';
import { useState } from 'react';
import { cartAtom, removeItemFromCart, showBookingDrawer, showSelectServices, storeDetailAtom } from '../App/App_state';
import { formatCost, formatDuration } from '../ServiceTile/ServiceTile';
import { Item } from '../App/App_Store_interface';

function CalculateTaxValue(value: number, taxPercent: number, isTaxIncluded: boolean) {
  if (taxPercent === 0) {
    return 0;
  }

  if (isTaxIncluded) {
    return value - (value * (100 / (100 + taxPercent)));
  } else {
    return (value * taxPercent) / 100;
  }
}

export default function Cart() {

  // store boolean state for toggling between select services and staff/ date / time selection screen 
  const [ShowSelectServicesState, toggleShowSelectServices] = useAtom(showSelectServices)

  // toggle booking form on booking button click 
  const toggleShowBookingDrawer = useSetAtom(showBookingDrawer)

  // array of selected items
  const [cartItems, setCartItems] = useAtom(cartAtom)

  const selectedEmployeeId = useAtomValue(selectedEmployeeIdAtom)
  const selectedDate = useAtomValue(selectedDateAtom)
  let selectedTime = useAtomValue(selectedTimeAtom)
  const storeDetail = useAtomValue(storeDetailAtom)!

  const isRetailStore = window.location.pathname.split('/')[3] === 'retail-booking'

  const selectedEmployee = isRetailStore ? undefined : storeDetail.employees!.filter((s) => s.id === selectedEmployeeId)[0]

  if (cartItems.length === 0) {
    return <EmptyCart />
  }

  // cart items net total value
  let netTotal = cartItems.map((s) => Number(s.cost) * s.cartQty).reduce((total, c) => total + c);
  // cart to total tax value
  let tax = cartItems.map((s) =>
    CalculateTaxValue(s.cost, ((s.cgstPercent ?? 0) + (s.sgstPercent ?? 0) + (s.igstPercent ?? 0)), s.isTaxIncluded ?? false) * s.cartQty
  ).reduce((total, c) => total + c);

  const grandTotal = cartItems.map((s) => {
    const subTotal = s.cost * s.cartQty
    const taxAmount = CalculateTaxValue(s.cost, ((s.cgstPercent ?? 0) + (s.sgstPercent ?? 0) + (s.igstPercent ?? 0)), s.isTaxIncluded ?? false) * s.cartQty

    return subTotal + (s.isTaxIncluded ? 0 : taxAmount);
  }).reduce((total, c) => total + c)

  // toggle book now button when data is valid
  let showBookNowButton = (ShowSelectServicesState || selectedTime)

  function refreshCart() {
    setCartItems([...cartItems])
  }

  return (
    <div id='Cart'>

      <h3 className='canvas cardLabel'>Cart</h3>

      <div className='canvas paddingMin' style={{
        borderTopLeftRadius: '0',
        marginBottom: '7px'
      }}>


        <div id='addMoreServicesButton'>
          <small>{`${cartItems.length} services`}</small>
          {
            ShowSelectServicesState === false &&
            <a href='#salonCard_container' aria-label='Add More Services' onClick={() => toggleShowSelectServices((v) => !v)}>
              {`Add more ${isRetailStore ? 'product' : 'services'}`}
            </a>
          }
        </div>

        {cartItems.map((service, i) => <ServiceTile key={i} refreshCart={refreshCart} service={service} />)}
      </div>



      <div id='Receipt'>

        <h3 className='canvas cardLabel'>Receipt</h3>

        <div className='canvas paddingMin' style={{
          borderTopLeftRadius: '0',
        }}>


          {
            ShowSelectServicesState === false
            && isRetailStore === false
            && <>
              {(storeDetail.defaultEmployeeId === undefined && storeDetail.defaultEmployeeId === null)
                &&
                <CartDetailTile
                  title={'Staff:'}
                  trailing={`${selectedEmployee!.firstName} ${selectedEmployee!.lastName ?? ''}`}
                />
              }

              <CartDetailTile
                title={'Date:'}
                trailing={`${selectedDate.toDateString().substring(0, 10)}`}
              />

              <CartDetailTile
                title={'Time:'}
                trailing={selectedTime === null ? 'please select time' : ` ${format(selectedTime, 'p').padStart(8, '0')}`}
                style={selectedTime === null ? { color: 'tomato' } : undefined}
              />
              <hr />
            </>
          }


          <CartDetailTile
            title={'Tax:'}
            trailing={` ₹ ${tax.toFixed(2) ?? 0}`}
          />

          <CartDetailTile
            title={'Net Total:'}
            trailing={`₹ ${formatCost(netTotal.toFixed(2))}`}
          />

          <CartDetailTile
            title={'Total:'}
            trailing={`₹ ${formatCost(grandTotal.toFixed(2))}`}
            style={{ fontWeight: '700' }}
          />

          {
            showBookNowButton
              ? <a
                href={isRetailStore ? '#open' : '#Booking'}
                aria-label='Book Now'
                className='canvasButton canvasDark'
                onClick={() => {
                  ShowSelectServicesState && selectedEmployee
                    ? toggleShowSelectServices((v) => !v)
                    : toggleShowBookingDrawer((v) => !v);
                }}
              >
                <h4>{ShowSelectServicesState ? 'Checkout' : 'Book Now'}</h4>
              </a>
              : <a
                href={isRetailStore ? '#open' : '#Booking'}
                aria-label='Book Now'
                className='canvasButton'
                style={{
                  background: 'rgb(190, 188, 188)'
                }}
              >
                <h4>{ShowSelectServicesState ? 'Checkout' : 'please select time to Book'}</h4>
              </a>
          }
        </div>

      </div>
    </div>
  )
}


// component to show inPlace of cart when cart is empty
function EmptyCart() {
  return (
    <div id='Cart'>
      <h3 className='canvas cardLabel'>Cart</h3>

      <div id='Empty-Cart' className='canvas'>
        <IoGridOutline size={42} color={'Grey'} />
        <small> Your Cart is Empty, Go ahead, Select some Items.</small>
      </div>
    </div>
  )
}

// item detail tile for cart
function ServiceTile(props: {
  service: Item,
  refreshCart: Function,
}) {

  // set item that have to remove from cart 
  // then show a dialog to remove that item from cart
  const setRemoveItemDialogItem = useSetAtom(removeItemFromCart)

  const [qty, setQty] = useState(props.service.cartQty)

  function toggleItemQty(isAddAction: boolean) {
    if (isAddAction) {
      setQty(qty + 1)
      props.service.cartQty = qty + 1
    } else if (qty === 1) {
      setRemoveItemDialogItem(props.service)
    } else {
      setQty(qty - 1)
      props.service.cartQty = qty - 1
    }
    props.refreshCart()
  }

  const gstPercent = (props.service.cgstPercent ?? 0) + (props.service.sgstPercent ?? 0)


  return (
    <div className={`CartServiceTile CartServiceTileForProduct`}>
      <div>
        <p>{props.service.name}</p>
        <small>{props.service.durationInMinutes === undefined ? props.service.uom : formatDuration(props.service.durationInMinutes)}</small>
      </div>
      <div style={{
        textAlign: 'right',
      }} >{`₹ ${formatCost(props.service.cost.toString())}`}
        <small>
          <br />
          {gstPercent >= 0 ? '' : `+ gst ${gstPercent} %`}
        </small>
      </div>
      <CartInventoryButton qty={qty} toggleItemQty={toggleItemQty} />
    </div>
  )
}

// cart details tile eg, employee name selected date, tax, net total..
function CartDetailTile(props: {
  title: string;
  trailing: string;
  style?: React.CSSProperties
}): JSX.Element {
  return (
    <div className='CartDetailTile'>
      <p style={props.style}>{props.title}</p>
      <p style={props.style}>{formatCost(props.trailing)}</p>
    </div>
  )
}


function CartInventoryButton(props: {
  qty: number,
  toggleItemQty: Function,
}): JSX.Element {
  return (
    <div className='CartInventoryButton'>
      <button aria-label='qtyToggleButton' onClick={() => props.toggleItemQty(false)} >
        <IoRemoveCircleSharp size={'24'} color={'tomato'} />
      </button>
      {props.qty}
      <button aria-label='qtyToggleButton' onClick={() => props.toggleItemQty(true)} >
        <IoAddCircle size={'24'} color={'tomato'} />
      </button>
    </div>
  )
}

// small screen button at bottom
export function CartButton() {
  // array of selected items
  const cartItems = useAtomValue(cartAtom)
  const selectedTime = useAtomValue(selectedTimeAtom)
  const showSelectServicesState = useAtomValue(showSelectServices)

  const toggleShowBookingDrawer = useSetAtom(showBookingDrawer)
  const toggleShowSelectServices = useSetAtom(showSelectServices)
  const selectedEmployeeId = useAtomValue(selectedEmployeeIdAtom)

  const isRetailStore = window.location.pathname.split('/')[3] === 'retail-booking'

  const storeDetail = useAtomValue(storeDetailAtom)!
  const selectedEmployee = isRetailStore ? undefined : storeDetail.employees!.filter((s) => s.id === selectedEmployeeId)[0]

  let showBookNowButton = (showSelectServicesState || selectedTime)


  if (cartItems.length === 0) {
    return <></>
  }

  const grandTotal = cartItems.map((s) => {
    const subTotal = s.cost * s.cartQty
    const taxAmount = CalculateTaxValue(s.cost, ((s.cgstPercent ?? 0) + (s.sgstPercent ?? 0) + (s.igstPercent ?? 0)), s.isTaxIncluded ?? false) * s.cartQty

    return subTotal + (s.isTaxIncluded ? 0 : taxAmount);
  }).reduce((total, c) => total + c)


  return (
    <div id='CartButtonBottom' className='canvas'>
      <div>
        <h1>{`₹ ${formatCost((grandTotal).toFixed(2))}`}</h1>
        <p style={{ padding: '0' }}>{`${cartItems.length} services`}</p>
      </div>

      {(showSelectServicesState === false)
        ?
        showBookNowButton
          ? <a
            href={isRetailStore ? '#open' : '#Booking'}
            aria-label='Book Now'
            className='canvasButton canvasDark'
            onClick={() => {
              showSelectServicesState && selectedEmployee
                ? toggleShowSelectServices((v) => !v)
                : toggleShowBookingDrawer((v) => !v);
            }}
          >
            <h4>Book Now</h4>
          </a>
          : <a
            href={isRetailStore ? '#open' : '#Booking'}
            aria-label='Book Now'
            className='canvasButton'
            style={{
              background: 'rgb(190, 188, 188)'
            }}
          >
            <h4 style={{ textAlign: 'center' }}>Select Time to Book</h4>
          </a>

        :
        <a
          href='#Booking'
          className='canvasButton canvasDark'
          aria-label='Cart'
          style={{ color: 'white', fontWeight: 'bold' }}
          onClick={() => {
            showSelectServicesState && selectedEmployee
              ? toggleShowSelectServices((v) => !v)
              : toggleShowBookingDrawer((v) => !v);
          }}
        >
          {showSelectServicesState ? 'Checkout' : 'Book Now'}
        </a>
      }
    </div>
  )
}


